.card {
  box-shadow: $card-shadow;
  background-color: $color-white;
}

.-content-card,
.-overlap-card {
  width: 100%;
  margin: 0 auto;
  padding: pxem(25) pxem(30);
  margin-bottom: pxem(100);
  z-index: 400;
  position: relative;
  transition: $t-1 240ms width;

  @include mq($from: $breakpoint-xsmall) {
    width: 90%;
  }

  @include mq($from: $breakpoint-small) {
    width: 60%;
  }

  @media (min-width: $breakpoint-xlarge) {
    width: 40%;
  }

  .-overlap-card {
    margin: pxem(60) auto 0 auto;
  }

  &.-line-bottom {

    &:after {
      position: absolute;
      content: "";
      width: 1px;
      height: pxem(120);
      background-color: $color-green;
      left: 50%;
      z-index: $header-z;
      top: 100%;

      @include mq($from: $breakpoint-small) {
        height: pxem(240);
      }
    }
  }
}
