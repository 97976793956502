.es-content-center {
  margin: pxem(160) auto pxem(110) auto;
  width: 90%;
  text-align: center;

  @include mq($from: $breakpoint-xsmall) {
    width: 80%;
  }

  @include mq($from: $breakpoint-small) {
    width: pxem(600);
  }
}
