.es-content-full {
    margin: 13vh 0;

  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  section {
    @include full(20);

    @include mq($from: $breakpoint-small) {
      @include half(45);
    }
  }

  .-images {
    margin: 0 0 13vh 0;
    text-align: center;

    @include mq($from: $breakpoint-small) {
      margin: 0;
      text-align: right;
    }
  }

  .-content {
    width: 100%;

    @include mq($from: $breakpoint-medium) {
      width: 90%;
    }

    @include mq($from: $breakpoint-large) {
      width: 80%;
    }
  }
}
