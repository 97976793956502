.es-three-column {
  margin: pxem(100) 0 pxem(175) 0;

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  section {
    @include full(15);
    margin: pxem(50) 0;

    @include mq($from: $breakpoint-small) {
      @include third(15);
      margin: 0;
    }

    .-image {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      padding-top: 100%;
      margin: 0 0 pxem(30) 0;
    }
  }
}
