.es-full-photo {
  z-index: $z-4;
  position: relative;
  margin-bottom: pxem(120);

  .-photo {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 48%;
    margin: pxem(80) 0 pxem(60) 0;

    @include mq($from: $breakpoint-small) {
      margin: 0 0 pxem(60) 0;
    }
  }

  .-photo.-underlap {
    margin: pxem(80) 0 pxem(120) 0;
    position: relative;

    @include mq($from: $breakpoint-small) {
      margin: pxem(-60) 0 pxem(300) 0;
    }

    &.-line-bottom {

      &:after {
        position: absolute;
        content: "";
        width: 1px;
        height: pxem(120);
        background-color: $color-green;
        left: 50%;
        z-index: $header-z;
        top: 100%;

        @include mq($from: $breakpoint-small) {
          height: pxem(240);
        }
      }
    }
  }
}
