.-img {
  &.-full {
    width: 100%;
  }

  &.-right {
    float: right;
  }

  &.-one-third {
    width: 50%;

    @include mq($from: $breakpoint-medium) {
      width: 33%;
    }
  }

  &.-two-thirds {
    width: 100%;

    @include mq($from: $breakpoint-small) {
      width: 70%;
    }
  }

  &.-center {
    margin: 0 auto;
    display: block;
  }
}
