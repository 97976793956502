.es-community {

  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .-item {
    @include full(30);
    margin: 0 0 pxem(50) 0;

    @include mq($from: $breakpoint-small) {
      @include half(30);
      margin: 0 0 pxem(120) 0;
    }

    figure {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      padding-top: 58%;
    }
  }

  .-content {
    padding: pxem(40) 0 0 0;
    margin: 0 0 0 pxem(40);
    position: relative;

    h2 {
      @include title-secondary;
      color: $text-dark;
      line-height: pxem(44);
    }

    p {
      @include text-paragraph;
      color: $text-dark;
    }

    a {

    }
    strong {
      margin-top: pxem(20);
    }
  }

  .-line-top {
    &:before {
      position: absolute;
      content: "";
      width: 1px;
      height: pxem(220);
      background-color: $color-green;
      top: -17%;
      left: pxem(-15);
      z-index: $content-z;
    }
  }
}
