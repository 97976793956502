/*
Global Styles
==============================
*/

html, body {
	position: relative;
  min-height: 100vh;
  min-width: 320px;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Din';
}

html {
  min-height: 100%;
}

body {
  height: 100%;
}

img {
  width: 100%;
  height: auto;
}

::selection {
  background: $color-green;
  color: $color-white;
}

::-moz-selection {
  background: $color-green;
  color: $color-white;
}

::-webkit-selection {
  background: $color-green;
  color: $color-white;
}

.-clear{
  @include clearfix;
}

.-pull-right {
  float: right;
}

.-pull-left {
  float: left;
}

.-center {
  text-align: center;
}
