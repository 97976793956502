.es-container-image-card {
  z-index: $z-5;
  margin-bottom: pxem(100);

  @include mq($from: $breakpoint-small) {
    margin-bottom: pxem(320);
  }

  .-content-card {
    z-index: $z-5;
    margin: 0 auto pxem(30) auto;

    @include mq($from: $breakpoint-small) {
      margin: 0 auto pxem(-70) auto;
    }
  }

  .container {
    position: relative;
  }

  &.-line-bottom {

    &:after {
      position: absolute;
      content: "";
      width: 1px;
      height: pxem(120);
      background-color: $color-green;
      left: 50%;
      z-index: $header-z;
      top: 100%;

      @include mq($from: $breakpoint-small) {
        height: pxem(240);
      }
    }
  }
}
