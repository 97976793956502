// Colors
$color-black:           #000000;
$color-white:           #ffffff;
$color-grey:            #999999;
$color-dark-grey:       #333333;
$color-green:           #7EC91E;
$color-steel:           #6B8080;
$color-light-grey:      #EEEEEE;

$text-light:            $color-white;
$text-dark:             $color-black;
$text-grey:             $color-grey;
$text-green:            $color-green;
$text-steel:            $color-steel;

// Button Colors

// Transitions
$t-1:                   cubic-bezier(0.2, 0.3, 0.25, 0.9);

// Shadows
$button-shadow:         0 16px 20px 0 rgba(0, 0, 0,.10);
$card-shadow:           $button-shadow;
$nav-shadow:            0 1px 4px 0 rgba(0, 0, 0, .15);

// Z-Indexes
$z-1:                               100;
$z-2:                               200;
$z-3:                               300;
$z-4:                               400;
$z-5:                               500;
$z-6:                               600;
$z-7:                               700;
$z-8:                               800;
$z-9:                               900;
$z-10:                              1000;
$z-11:                              1100;

// Z applied
$nav-z:                             $z-10;
$header-z:                          $z-1;
$content-z:                         $z-3;
$foreground-z:                      $z-4;
$mobile-nav-z:                      $z-5;
$mobile-icon-z:                     $z-6;
$line-z:                            $z-1;
$line-content-z:                    $z-2;
$form-z:                            $z-2;
$form-thanks-z:                     $z-1;

// Breakpoints
$breakpoint-xsmall:    480px;
$breakpoint-small:     768px;
$breakpoint-medium:    900px;
$breakpoint-large:     1080px;
$breakpoint-laptop:    1260px;
$breakpoint-xlarge:    1600px;
$breakpoint-container: 1400px;

$breakpoint-map: (
  "default": 0,
  "xs":      $breakpoint-xsmall,
  "sm":      $breakpoint-small,
  "md":      $breakpoint-medium
);

// Set the number of columns you want to use on your layout.
$grid-columns: 6 !default;
// Set the gutter between columns.
$gutter-width: 1.875rem !default;
// Set a margin for the container sides.
$outer-margin: 20px !default;
