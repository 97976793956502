.sorting {
  .-item {
    cursor: pointer;
    
    &:after {
      content: "|";
      color: $color-grey;
      margin: 0 pxem(10);
    }

    &:hover,
    &:active {
      color: $color-black;
    }
  }

  .-item:last-of-type {
    &:after {
      display: none;
    }
  }
}
