.es-home-hero {
  width: 100vw;
  height: 85vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  top: 0;
  left: 0;
  z-index: $header-z;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: pxem(60);
  }

  h1 {
    padding-bottom: pxem(25);
    text-align: center;
  }

  p {
    width: 100%;
    text-align: center;
    margin-bottom: pxem(45);

    @include mq($from: $breakpoint-small) {
      width: 70%;
    }
  }

  .-play-video {
    width: pxem(55);
    height: pxem(55);

    img {
      margin-left: -10px;
    }
  }
}
