.contact {
  .-body {
    background-color: #F8F8F8;
  }

  .es-page-header,
  .es-contact,
  .es-contact-footer {
    .container {
      @include mq($from: $breakpoint-small) {
        width: 60%;
      }

      @include mq($from: $breakpoint-large) {
        width: 40%;
      }

      @include mq($from: $breakpoint-xlarge) {
        width: 27%;
      }
    }
  }

  .es-page-header {
    margin-bottom: 0;
  }

  .es-contact-footer {
    padding: pxem(55) 0 pxem(120) 0;

    .container {
      display: flex;
      flex-wrap: wrap;
    }

    .-item {
      @include full(25);
      margin: 0 0 pxem(50) 0;

      @include mq($from: $breakpoint-xsmall) {
        @include half(25);
        margin: 0;
      }
    }

    span,
    a {
      display: block;
      line-height: 1.5rem;
    }
  }
}
