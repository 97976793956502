.es-community-post {
  position: relative;
  z-index: 1;

  .-meta {
    list-style: none;
    display: flex;
    margin: 0 0 pxem(30) 0;

    li {
      display: inline-block;
      @include third(10);

      @include mq($from:$breakpoint-small) {
        @include fifth(10);
      }

      h3,
      h5 {
        color: $text-grey;
      }

      h5 {
        margin: pxem(-10) 0 0 0;
        text-transform: uppercase;
      }
    }
  }

  .-image:nth-of-type(2n) {
    margin: 1rem 0 0 0;

    @include mq($from: $breakpoint-small) {
      margin: -8rem 0 0 0;
    }
  }

  section:nth-of-type(2n) {
    margin: pxem(60) 0;

    @include mq($from: $breakpoint-small) {
        margin: pxem(110) 0;
      }
  }

  .-photo {
    background-image: url('../images/home-bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 48%;
    margin: pxem(80) 0 pxem(60) 0;

    @include mq($from: $breakpoint-small) {
      margin: 0 0 pxem(60) 0;
    }
  }
}
