.es-content-left-image {
  margin: pxem(150) 0 pxem(150) 0;

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .-content {
    width: 100%;
    margin: 0 0 13vh 0;

    @include mq($from: $breakpoint-medium) {
      width: 90%;
      padding: pxem(50) 0 0 0;
      margin: 0;
    }

    @include mq($from: $breakpoint-large) {
      width: 80%;
    }
  }

  section,
  .-image {
    flex-grow: 0;
    flex-shrink: 0;
    @include full(10);
    position: relative;

    @include mq($from: $breakpoint-small) {
      @include half(10);
    }
  }

  .-image {
    position: relative;

    img {
      width: 100%;
      height: auto;
      position: relative;

      @include mq($from: $breakpoint-small) {
        top: pxem(50);
        position: absolute;
        width: auto;
        height: 100%;
      }
    }
  }
}
