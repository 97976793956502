// @mixin RobotoRegular {
//   font-family: 'Roboto', sans-serif;
//   font-weight: 400;
//   font-style: normal;
// }

@mixin DinLight {
  font-family: 'DIN-Light', sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin DinMedium {
  font-family: 'DIN-Medium', sans-serif;
  font-weight: 400;
  font-style: normal;
}
