.mobile-nav {
  position: fixed;
  bottom: pxem(20);
  width: 100%;
  z-index: $z-11;

  @include mq($from: $breakpoint-xsmall) {
    bottom: pxem(40);
  }

  @include mq($from: $breakpoint-medium) {
    display: none;
  }

  .toggle-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #nav-toggle {
    width: pxem(53);
    height: pxem(53);
    border-radius: 50%;
    border: 2px solid $color-dark-grey;
    position: relative;
    box-shadow:$button-shadow;
    margin-left: 0;

    .-icon {
      background-color: $color-white;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    span {
      left: 15px;
    }

    span,
    span:before,
    span:after {
      position: absolute;
      display: block;
      width: 20px;
      height: 2px;
      background-color: $color-dark-grey;
      z-index: $z-10;
      content: '';
      top: 24px;
      transition: all 200ms ease-in-out;
    }

    span:before {
      top: -6px;
    }

    span:after {
      bottom: -27px;
      top: 6px;
    }

    &.active {
      span {
        background-color: transparent;
      }

      span:before,
      span:after {
        top: 0;
      }

      span:before {
        transform: rotate(45deg);
      }

      span:after {
        transform: rotate(-45deg);
      }
    }
  }
}

.navigation {
  height: 0;
  width: 100vw;
  transition: height 240ms $t-1;
  z-index: $z-10;
  background-color: $color-white;
  position: fixed;
  bottom: 0;

  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: pxem(25) 0 0 0;
  }

  .-mobile-logo {
    background-image: url('../images/logo-dark@2x.png');
    width: pxem(53);
    height: pxem(53);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: $z-10;
  }

  .container {
    height: 100vh;

    @include mq($from: $breakpoint-small) {
      margin: 9vh auto;
    }
  }

  &.-open {
    height: 101vh;
  }

  .-mobile-links {
    opacity: 0;
    transition: opacity 240ms $t-1;
    display: flex;
    flex-direction: column;
    z-index: $z-10;
    margin-top: pxem(40);


    li {
      padding: pxem(10) 0;

      @include mq($from: $breakpoint-small) {
        padding: pxem(20) 0;
      }
    }

    li.-has-children {
      padding: pxem(10) 0 0 0;

      @include mq($from: $breakpoint-small) {
        padding: pxem(20) 0 0 0;
      }
    }

    .-sub-nav {
      padding: pxem(10) 0 0 pxem(10);

      @include mq($from: $breakpoint-small) {
        padding: pxem(20) 0 0 pxem(20);
      }

      li {
        padding: pxem(10) 0;

        @include mq($from: $breakpoint-small) {
          padding: pxem(20) 0;
        }
      }
    }

    &.-visible {
      opacity: 1;
    }
  }
}
