.button {
  @include link-regular;
  cursor: pointer;
  display: inline-block;
  border-radius: pxem(100);
  padding: pxem(10) pxem(40);
  line-height: 1;
  margin-top: pxem(20);

  &.-solid {
    background-color: $color-green;
    color: $text-light;
    border: none;
    transition: background-color $t-1 240ms;

    &:hover {
      // margin-top: pxem(-2);
    }

    &:active {
      background-color: darken($color-green, 10%);
    }
  }

  &.-shadow {
    position: relative;

    &:after {
      position: absolute;
      top: 0;
      left:0;
      border-radius: pxem(100);
      width: 100%;
      height: 100%;
      content: "";
      box-shadow: $button-shadow;
      opacity: 1;
      transition: opacity $t-1 240ms;
    }

    &:hover {
      &:after {
        opacity: 0;
      }
    }
  }

  &.-hollow-green {
    background-color: transparent;
    padding: pxem(10) pxem(40);
    color: $text-green;
    border: 1px solid $color-green;
    transition: background-color $t-1 240ms;
    transition: color $t-1 240ms;

    &:hover {
      color: $text-light;
      background-color: $color-green;
    }

    &:active {
      background-color: darken($color-green, 10%);
      border: 1px solid darken($color-green, 10%);
    }
  }

  &.-hollow-steel {
    background-color: transparent;
    padding: pxem(10) pxem(40);
    color: $text-steel;
    border: 1px solid $color-steel;
    transition: background-color $t-1 240ms;
    transition: color $t-1 240ms;

    &:hover {
      color: $text-light;
      background-color: $color-steel;
    }

    &:active {
      background-color: darken($color-steel, 10%);
      border: 1px solid darken($color-steel, 10%);
    }
  }
}
