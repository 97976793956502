.es-content-grid {
  z-index: $content-z;
  padding-top: pxem(110);
  margin-bottom: pxem(100);

  @include mq($from: $breakpoint-small) {
    margin-bottom: 0;
  }

  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .-image,
  section {
    flex-grow: 0;
    flex-shrink: 0;
    @include full(10);
    position: relative;

    @include mq($from: $breakpoint-small) {
      @include half(10);
    }
  }

  .-image:nth-of-type(1n) {
    margin: pxem(30) 0;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../images/home-portrait.png");
    @include full(10);
    height: 600px;
    width: auto;
    order: 2;

    @include mq($from: $breakpoint-small) {
      flex-basis: 35%;
      margin: 7.5rem 0 0 0;
      order: 1;
    }
  }

  .-image:nth-of-type(2n) {
    background-image: url('../images/home-desk.png');
    background-size: 100% auto;
    margin: pxem(60) 0 pxem(120) 0;
    @include full(10);
    order: 4;
    height: 620px;
    width: auto;

    @include mq($from: $breakpoint-small) {
      @include half(10);
      // padding-top: 92%;
      margin: -8rem 0 0 0;
    }
  }

  section {
    .-content {
      width: 100%;

      @include mq($from: $breakpoint-medium) {
        width: 90%;
      }

      @include mq($from: $breakpoint-large) {
        width: 80%;
      }
    }

    &:nth-of-type(1n) {
      order: 1;

      @include mq($from: $breakpoint-small) {
        order: 2;
        margin-bottom: pxem(200);
      }
    }

    &:nth-of-type(2n) {
      margin: pxem(30) 0 0 0;
      order: 3;

      @include mq($from: $breakpoint-small) {
        margin: pxem(220) 0 pxem(160) 0;
      }
    }
  }

  .-line-top {
    &:before {
      position: absolute;
      content: "";
      width: 1px;
      height: pxem(320);
      background-color: $color-green;
      top: pxem(-220);
      left: pxem(-15);
      z-index: $content-z;
    }
  }

  .-line-bottom {
    &:after {
      position: absolute;
      content: "";
      width: 1px;
      height: pxem(320);
      background-color: $color-green;
      left: 50%;
      top: 100%;

      @include mq($from: $breakpoint-small) {
        left: pxem(-15);
      }
    }
  }
}
