.form {
  width: 100%;
  opacity: 1;
  transition: opacity 240ms $t-1;
  position: relative;
  z-index: $form-z;

  &.-invisible {
    opacity: 0;
  }

  .-row {
    display: flex;
    flex-wrap: wrap;

    justify-content: space-between;
    padding: pxem(15) 0;

    input,
    textarea {
      padding: pxem(12) pxem(10);
      border: 1px solid $color-grey;
      border-radius: 2px;
      background-color: $color-white;

      &:active,
      &:focus {
        border: 1px solid $color-green;
      }
    }

    input {
      @include full(0);

      &#firstName {
        margin: pxem(30) 0;
      }

      @include mq($from: $breakpoint-small) {
        @include half(10);

        &#firstName,
        &#lastName {
          margin: 0;
        }
      }

      @include mq($from: $breakpoint-container) {
        @include full(0);
        &#firstName {
          margin: pxem(30) 0;
        }
      }
    }

    textarea {
      height: pxem(160);
    }

    input#email,
    textarea {
      flex-basis: 100%;
    }
  }

  .-error {
    border: 1px solid #FF0000;
  }

  .-button-row {
    display: block;
    text-align: right;
  }
}

#contact-thanks {
  position: absolute;
  z-index: $form-thanks-z;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 240ms $t-1;

  &.-invisible {
    opacity: 0;
  }
}
