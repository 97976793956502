.community {

  .es-page-hero {

    h1 {
      color: $text-light;
    }

    h4 {
      color: $text-light;
    }
  }

  .-content-card {
    z-index: $z-5;
    margin: 0 auto pxem(30) auto;

    @include mq($from: $breakpoint-small) {
      margin: 0 auto pxem(-70) auto;
    }

    a {
      @include link-chevron;
      line-height: pxem(26);
      position: relative;
      color: $text-green;

      &:after {
        content: "";
        background-image: url('../images/green-right-carat.png');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        position: absolute;
        height: pxem(10);
        width: pxem(5);
        top: pxem(4);
        right: pxem(-10);
        transition: right $t-1 240ms;
      }

      &:hover {
        color: darken($text-green, 10%);

        &:after {
          right: pxem(-20);
        }
      }

      &:active {

        &:after {
          right: pxem(-20);
          top: pxem(-5);
        }
      }
    }
  }

  .-line-bottom {

    &:after {
      position: absolute;
      content: "";
      width: 1px;
      height: pxem(120);
      background-color: $color-green;
      left: 50%;
      top: 100%;
      z-index: $z-9;

      @include mq($from: $breakpoint-small) {
        left: pxem(-15);
      }

      @include mq($from: $breakpoint-medium) {
        height: pxem(240);
        bottom: -160px;
        top: auto;
      }

      @include mq($from: $breakpoint-laptop) {
        bottom: auto;
        top: 100%;
      }
    }
  }
}
