.news {

  .es-page-header {

    h1 {
      color: $text-dark;
    }

    .-links {
      list-style: none;
      margin: 0;
    }

    li {
      display: inline-block;

      a {
        @include title-epsilon;
        color: $text-grey;
        padding: pxem(10) 0;

        &.-active,
        &:hover {
          color: $text-dark;
        }
      }

      &:after {
        content: "|";
        font-size: pxem(20);
        display: inline-block;
        color: $text-grey;
        margin: 0 pxem(20);
      }

      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }
  }

  .es-article {
    width: 80%;
    margin: 0 auto;
    padding: pxem(50) 0;
    border-bottom: 1px solid $color-light-grey;

    &:last-of-type {
      border-bottom: 0;
      padding: pxem(50) 0 pxem(250) 0;
    }

    @include mq($from: $breakpoint-small) {
      width: 60%;
    }

    @include mq($from: $breakpoint-large) {
      width: 40%;
    }

    @include mq($from: $breakpoint-xlarge) {
      width: 27%;
    }

    .-image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      padding-top: 70%;
      margin: 0 0 pxem(25) 0;
    }

    .-meta {
      margin-bottom: pxem(8);

      span {
        @include text-meta;
        color: $text-grey;
        display: inline-block;
        text-transform: uppercase;

        &:first-of-type {
          &:after {
            content: "|";
            color: $text-grey;
            font-size: pxem(20);
            display: inline-block;
            margin: 0 pxem(20);
          }
        }
      }
    }

    .-content {
      h2 {
        line-height: 2rem;
      }

      h2 > a  {
        @include title-secondary;
        margin: pxem(5) 0 pxem(30) 0;
        color: $text-dark;
        transition: color 240ms $t-1;
        line-height: 2rem;

        &:after {
          display: none;
        }

        &:hover {
          color: lighten($text-dark, 45%);
        }
      }

      p {
        margin: 0 0 pxem(20) 0;
      }

      a {
        @include link-chevron;
        line-height: pxem(26);
        position: relative;
        color: $text-green;

        &:after {
          content: "";
          background-image: url('../images/green-right-carat.png');
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: center center;
          position: absolute;
          height: pxem(10);
          width: pxem(5);
          top: pxem(4);
          right: pxem(-10);
          transition: right $t-1 240ms;
        }

        &:hover {
          color: darken($text-green, 10%);

          &:after {
            right: pxem(-20);
          }
        }

        &:active {

          &:after {
            right: pxem(-20);
            top: pxem(-5);
          }
        }
      }
    }
  }
}
