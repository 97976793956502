.es-content-right-image {
  margin: pxem(140) 0 pxem(220) 0;

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  section.-line-top {
    order: 1;
    margin: 0 0 pxem(100);

    @include mq($from: $breakpoint-small) {
      order: 2;
      margin: 0;
    }

    @include mq($from: $breakpoint-medium) {
      width: 90%;
    }

    @include mq($from: $breakpoint-large) {
      width: 80%;
    }
  }

  .-content {
    width: 100%;

    @include mq($from: $breakpoint-medium) {
      width: 90%;
    }

    @include mq($from: $breakpoint-large) {
      width: 80%;
    }
  }

  .-image {
    order: 2;

    @include mq($from: $breakpoint-small) {
      order: 1;
    }
  }

  section,
  .-image {
    flex-grow: 0;
    flex-shrink: 0;
    @include full(10);
    position: relative;

    @include mq($from: $breakpoint-small) {
      @include half(10);
    }
  }

  .-line-top {
    &:before {
      position: absolute;
      content: "";
      width: 1px;
      height: pxem(310);
      background-color: $color-green;
      top: pxem(-215);
      left: pxem(-15);
      z-index: $content-z;
    }
  }
}
