.-line-bottom {

  &:after {
    position: absolute;
    content: "";
    width: 1px;
    height: pxem(220);
    background-color: $color-green;
    left: 50%;
    z-index: $line-z;
    top: 80%;
  }
}
