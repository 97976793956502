.es-page-header {
  margin-bottom: pxem(85);

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: pxem(110) 0 pxem(50) 0;
  }

  p {
    @include half(0);
    flex-grow: 0;
    flex-shrink: 0;
    text-align: center;
  }
}
