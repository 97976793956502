.es-content-right {
  margin: 0 0 13vh 0;

  @include mq($from: $breakpoint-small) {
    margin: 0;
  }

  .container {
    display: flex;
    justify-content: flex-end;
  }

  section {
    position: relative;
    padding: pxem(95) 0 0 0;
    @include full(10);

    @include mq($from: $breakpoint-small) {
      @include half(10);
    }
  }

  .-content {
    width: 100%;

    @include mq($from: $breakpoint-medium) {
      width: 90%;
    }

    @include mq($from: $breakpoint-large) {
      width: 80%;
    }
  }

  .-line-top {
    &:before {
      position: absolute;
      content: "";
      width: 1px;
      height: pxem(250);
      background-color: $color-green;
      top: pxem(-55);
      left: pxem(-15);
      z-index: 0;
    }
  }
}
