html {
  font-size: 100%;

  @include mq($from: $breakpoint-small) {
    font-size: calc(100% + 2 * (100vw - 768px) / 400);
  }

  @include mq($from: $breakpoint-laptop) {
    font-size: calc(100% + 4 * (100vw - 900px) / 600);
  }

  @include mq($from: $breakpoint-container) {
    font-size: calc(133.3%);
  }
}
