.nav {
  top: 0;
  left: 0;
  z-index: $nav-z;
  width: 100%;
  position: fixed;
  transition: transform 240ms ease-in-out;
  border-bottom: 1px solid #D9D9D9;
  background-color: $color-white;
  box-shadow: $nav-shadow;

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: pxem(15) 0 pxem(10) 0;
    margin: 0 auto;

    @include mq($from: $breakpoint-medium) {
        justify-content: space-between;
    }

    .-logo {
      width: pxem(53);
      height: pxem(53);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      @include mq($from: $breakpoint-medium) {
        width: pxem(60);
        height: pxem(60);
      }
    }

    .-links {
      display: none;

      @include mq($from: $breakpoint-medium) {
        justify-content: space-between;
        display: flex;
      }

      > li {
        padding: 0 pxem(5);
        cursor: pointer;

        > a {
          padding: pxem(35) pxem(20) pxem(35) pxem(20);
          position: relative;
          @include link-nav-dark;

          &:after {
            content: "";
            position: absolute;
            bottom: pxem(3);
            left: 0;
            width: 100%;
            height: pxem(3);
            background-color: $color-green;
            opacity: 0;
            transition: opacity 240ms $t-1;
          }

          &:hover {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }

    .-has-children {
      position: relative;

      .-sub-nav {
        position: absolute;
        opacity: 0;
        bottom: pxem(-100);
        left: 0;
        background-color: $color-white;
        box-shadow: $card-shadow;
        padding: pxem(5) 0;
        width: pxem(150);
        transition: opacity 240ms $t-1;

        > li {
          display: block;
          background-color: transparent;
          transition: background-color 240ms $t-1;

          > a {
            line-height: pxem(36);
            padding: pxem(15) 0 pxem(15) pxem(30);
            width: 100%;
            @include link-nav-dark;
          }

          &:hover {
            background-color: $color-light-grey;
          }
        }
      }

      &:hover {
        .-sub-nav {
          display: inline-block;
          opacity: 1;
        }
      }
    }
  }

  &.-transparent {
    box-shadow: none;
    border: 0;
    background-color: transparent;

    .-links {
      > li > a {
        @include link-nav-light;

        &:after {
          background-color: $color-white;
        }
      }
    }
  }
}
