.text {
  &.-paragraph {
    @include text-paragraph;
    line-height: pxem(24);
  }

  &.-paragraph-bold {
    @include text-paragraph-bold;
    line-height: pxem(24);
  }

  &.-sub {
    @include text-sub;
    line-height: pxem(28);
  }

  &.-meta {
    @include text-meta;
    line-height: pxem(28);
  }

  &.-footer {
    @include text-footer;
    line-height: pxem(24);
  }

  &.-footer-bold {
    @include text-footer-bold;
    line-height: pxem(24);
  }

  &.-light {
    color: $text-light;
  }

  &.-dark {
    color: $text-dark;
  }

  &.-grey {
    color: $text-grey;
  }

  &.-uppercase {
    text-transform: uppercase;
  }
}
