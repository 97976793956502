/*
Default Text Styles
==============================
*/


h1 {
  @include title-alpha;
  line-height: pxem(60);
}

h2 {
 @include title-secondary;
  margin-bottom: pxem(22);
}

h3 {
  @include title-delta;
  line-height: pxem(44);
}

h4 {
 @include title-gamma;
 line-height: pxem(28);
}

h5 {
  @include title-epsilon;
  line-height: pxem(34);
}

h6 {

}

hr {
  width: 100%;
  height: 1px;
  background-color: #999999;
}

.-dark {
  color: $color-black !important;
}

.-light {
  color: $color-white !important;
}

.-grey {
  color: $color-grey !important;
}

.-green {
  color: $color-green !important;
}

p {
  @include text-paragraph;
  margin-bottom: pxem(22);
  line-height: pxem(24);
}

ol {
  @include text-paragraph;
}

ul {
  @include text-paragraph;
  list-style: none;
}

blockquote {
  @include text-paragraph;
  padding: pxem(20) 0 pxem(20) pxem(20);
  border-left: 2px solid $color-green;
  margin: 0 0 0 pxem(20);
}

.-title-section {
  @include title-delta;
  margin-bottom: 50px;
}

.-title-meta {
  @include title-delta;
}
