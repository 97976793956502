.es-full-image-card {
  z-index: $z-4;
  position: relative;
  margin-bottom: pxem(120);

  .-underlap-card {
    margin: pxem(30) auto;

    @include mq($from: $breakpoint-small) {
      margin: pxem(-60) auto 0 auto;
    }
  }

  .-card-image {
    display: flex;
    justify-content: flex-end;

    img {
      max-width: 200px;
      width: 100%;
    }
  }

  &.-line-bottom {

    &:after {
      position: absolute;
      content: "";
      width: 1px;
      height: pxem(120);
      background-color: $color-green;
      left: 50%;
      z-index: $header-z;
      top: 100%;

      @include mq($from: $breakpoint-small) {
        height: pxem(240);
      }
    }
  }
}
