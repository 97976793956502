.single {

    .es-full-page-image {
      margin: pxem(175) 0 0 0;
      background-image: url('../images/vision-photo.jpg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      padding-top: 48%;
    }

    .-content-card {
      margin: pxem(-150) auto 0 auto;
      z-index: $z-5;

      span {
        display: block;
        text-align: right;
      }
    }

    .es-page-hero {

      .title {
        width: 62%;
        margin: 0 auto;
      }

      .-image {
        background-image: url('../images/technology-hero.png');
      }
    }

    .-content-card {
      margin: pxem(60) auto 0 auto;
      z-index: $content-z;
      position: relative;
    }

    .-overlap-card {
      margin: 0 auto;
    }

    .es-content-columned {
      background-color: $color-white;
      position: relative;
      z-index: $z-3;

      @include mq($from: $breakpoint-medium) {
        background-color: transparent;
      }
    }
}
