.es-page-hero {
  position: relative;
  margin-bottom: -8%;
  display: block;
  overflow: hidden;
  padding: 9% 0 18% 0;

  .-image {
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: $header-z;
  }

  .container {
    text-align: center;
    z-index: $content-z;
    position: relative;
  }

  h1 {
    margin-bottom: pxem(20);
    color: $text-light;
  }

  h4 {
    text-transform: uppercase;
    margin-bottom: pxem(0);
    color: $text-light;
  }
}
