.es-image-grid {
  margin: pxem(105) 0 0 0;
  z-index: $z-3;
  position: relative;

  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .-line-bottom {
    &:after {
      position: absolute;
      content: "";
      width: 1px;
      height: pxem(170);
      background-color: $color-green;
      left: 50%;
      top: 100%;
    }
  }

  .-image {
    @include full(0);
    background-size: cover;
    padding-top: 48.7%;
    margin: pxem(15) 0;
    position: relative;
    z-index: $z-5;

    &:nth-of-type(1n),
    &:nth-of-type(2n) {
      @include mq($from: $breakpoint-small) {
        @include half(15);
        padding-top: 30.3%;
      }
    }

    &:nth-of-type(3n) {
      @include mq($from: $breakpoint-small) {
        @include full(0);
        padding-top: 48.7%
      }
    }
  }
}
