.article {
  .es-page-header {
    h1 {
      color: $text-dark;
      transition: color 240ms $t-1;

      &:hover {
        color: lighten($text-dark, 10%);
      }
    }
  }

.es-article-body {
  .-main-image {
    background-image: url('../images/groundbreaking-full.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding-top: 48%;
  }

  article {
    width: 80%;
    margin: 0 auto;
    padding: pxem(50) 0;
    border-bottom: 1px solid $color-light-grey;

    &:last-of-type {
      border-bottom: 0;
      padding: pxem(50) 0 pxem(250) 0;
    }

    @include mq($from: $breakpoint-small) {
      width: 60%;
    }

    @include mq($from: $breakpoint-large) {
      width: 40%;
    }

    @include mq($from: $breakpoint-container) {
      width: 27%;
    }

    img {
      margin: 0 0 pxem(25) 0;
    }

    h2 {
      line-height: 2rem;
    }

    p {
      line-height: 1.75rem;
    }

    .-meta {
      margin-bottom: pxem(8);

      span {
        display: inline-block;
        text-transform: uppercase;
        @include text-meta;
        color: $text-grey;

        &:first-of-type {
          &:after {
            content: "|";
            color: $text-grey;
            font-size: pxem(20);
            display: inline-block;
            margin: 0 pxem(20);
          }
        }
      }
    }
  }

    .-content {
      p {
        a {
          position: relative;
          color: $text-dark;

          &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: pxem(2);
            background-color: $text-dark;
            bottom: pxem(-2);
            left: 0;
            transition: height $t-1 240ms;
          }

          &:hover {
            &:after {
              height: pxem(3);
            }
          }
        }
      }
    }
  }
}
