.title {
  &.-main {
    @include title-alpha;
    line-height: pxem(60);
  }

  &.-secondary {
    @include title-secondary;
    line-height: pxem(44);
  }

  &.-tertiary {
    @include title-delta;
    line-height: pxem(44);
  }

  &.-sub {
    @include title-gamma;
    line-height: pxem(28);
  }

  &.-meta {
    @include title-epsilon;
    line-height: pxem(34);
  }

  &.-contact {
    @include title-contact;
    line-height: pxem(44);
  }

  &.-light {
    color: $text-light;
  }

  &.-dark {
    color: $text-dark;
  }

  &.-grey {
    color: $text-grey;
  }

  &.-green {
    color: $text-green;
  }

  &.-steel {
    color: $text-steel;
  }

  &.-uppercase {
    text-transform: uppercase;
  }
}
