@mixin full($gutter) {
  flex-basis: calc(100% - #{$gutter/16}rem);
  // flex-basis: 100%;
  flex-shrink: 0;
  flex-grow: 0;
}

@mixin half($gutter) {
  flex-basis: calc(50% - #{$gutter/16}rem);
  // flex-basis:50%;
  flex-shrink: 0;
  flex-grow: 0;
}

@mixin third($gutter) {
  flex-basis: calc(33% - #{$gutter/16}rem);
  // flex-basis: 33%;
  flex-shrink: 0;
  flex-grow: 0;
}

@mixin quarter($gutter) {
  flex-basis: calc(25% - #{$gutter/16}rem);
  // flex-basis: 25%;
  flex-shrink: 0;
  flex-grow: 0;
}

@mixin fifth($gutter) {
  flex-basis: calc(20% - #{$gutter/16}rem);
  // flex-basis: 20%;
  flex-shrink: 0;
  flex-grow: 0;
}
