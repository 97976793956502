.es-full-card {
  padding: pxem(60) pxem(30);
  text-align: center;
  z-index: $foreground-z;
  position: relative;

  .-illustrations {
    display: none;
    
    @include mq($from: $breakpoint-small) {
      display: block;
    }
  }

  .-padded:first-of-type {
    padding: pxem(20) pxem(20) 0 0;
  }

  .-padded:last-of-type {
    padding: pxem(20) 0 0 pxem(20);
  }

  .-padded {
    padding: pxem(20) pxem(20) 0 pxem(20);
    vertical-align: bottom;
    text-align: center;
  }

  .link {
    padding: pxem(20) 0 0 0;
    display: block;
  }

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: pxem(50);

    @include mq($from: $breakpoint-small) {
      display: none;
    }
  }

  li {
    flex-basis: 100%;
    text-align: center;
    margin: pxem(40) 0;


    @include mq($from: $breakpoint-xsmall) {
      @include half(30);
    }

    @include mq($from: $breakpoint-small) {
      @include quarter(60);
      margin: 0;
    }
  }

}
