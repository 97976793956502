.-line-top {

  &:before {
    position: absolute;
    content: "";
    width: 1px;
    height: pxem(310);
    background-color: $color-green;
    top: pxem(-215);
    left: pxem(-15);
    z-index: $line-z;
    bottom: 100%;
  }
}
