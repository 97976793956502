.link {
  &.-nav {
    @include link-nav-dark;
    line-height: pxem(24);
  }

  &.-regular {
    @include link-regular;
  }

  &.-chevron {
    @include link-chevron;
    line-height: pxem(26);
    position: relative;
    color: $text-green;

    &:after {
      content: "";
      background-image: url('../images/green-right-carat.png');
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      height: pxem(10);
      width: pxem(5);
      top: pxem(4);
      right: pxem(-10);
      transition: right $t-1 240ms;
    }

    &:hover {
      color: darken($text-green, 10%);

      &:after {
        right: pxem(-20);
      }
    }

    &:active {

      &:after {
        right: pxem(-20);
        top: pxem(-5);
      }
    }
  }

  &.-paragraph {
    position: relative;
    color: $text-dark;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: pxem(2);
      background-color: $text-dark;
      bottom: pxem(-2);
      left: 0;
      transition: height $t-1 240ms;
    }

    &:hover {
      &:after {
        height: pxem(3);
      }
    }
  }

  &.-mobile-nav {
    @include link-mobile-nav;
    color: $text-dark;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: pxem(0);
      background-color: $text-dark;
      bottom: pxem(-2);
      left: 0;
      transition: height $t-1 240ms;
    }

    &:hover {
      &:after {
        height: pxem(3);
      }
    }
  }

  &.-green {
    color: $text-green;
  }

  &.-light {
    color: $text-light;
  }

  &.-dark {
    color: $text-dark;
  }

  &.-grey {
    color: $text-grey;
  }

  &.-steel {
    color: $text-steel;
  }

  &.-uppercase {
    text-transform: uppercase;
  }
}
