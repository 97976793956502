.footer {
  padding-top: pxem(35);
  border-top: 1px solid #D9D9D9;
  background-color: $color-white;
  position: relative;

  .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .-logo {
    width: 100%;
    text-align: center;

    @include mq($from: $breakpoint-medium) {
      text-align: left;
      width: 30%;
    }
  }

  #logo {
    width: pxem(53);
    height: pxem(53);
    margin-bottom: pxem(40);
  }

  .-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include mq($from: $breakpoint-medium) {
      width: 70%;
    }

    .-col {
      flex-basis: 100%;
      flex-grow: 0;
      flex-shrink: 0;
      margin: 0 0 pxem(20) 0;

      @include mq($from: $breakpoint-xsmall) {
        @include half(0);
      }

      @include mq($from: $breakpoint-small) {
        @include quarter(0);
      }

      @include mq($from: $breakpoint-medium) {
        @include fifth(0);
        margin: 0;
        flex-grow: 1;
      }

      ul {
        list-style: none;
        padding: pxem(10) 0 0 0;

        li {
          line-height: pxem(24);
          cursor: pointer;
        }
      }
    }
  }

  a {
    color: $text-dark;

    &:hover {
      color: lighten($text-dark, 35%);
    }
  }

  .meta-text {
    text-align: center;
    margin: pxem(50) 0 0 0;

    h5 {
      display: block;

      @include mq($from: $breakpoint-xsmall) {
        display: inline-block;
      }
    }

    .-first {
      &:after {
        display: none;

        @include mq($from: $breakpoint-xsmall) {
          content: "|";
          display: inline-block;
          margin: 0 pxem(10);
        }
      }
    }
  }
}
