.es-content-columned {
  margin: pxem(60) auto pxem(120) auto;
  background-color: $color-white;
  position: relative;
  z-index: $z-5;

  @include mq($from: $breakpoint-small) {
    background-color: transparent;
  }

  h2 {
    width: 100%;
    margin: 0 auto pxem(22) auto;

    @include mq($from: $breakpoint-medium) {
      width: 75%;
    }
  }

  .-content {
    column-count: 1;
    column-gap: pxem(30);
    margin: 0 auto;
    width: 100%;

    @include mq($from: $breakpoint-small) {
      column-count: 2;
    }


    @include mq($from: $breakpoint-medium) {
      width: 75%;
    }
  }
}
